import HomeService from '@/services/home/homeService.js';
export default {
  data: function () {
    return {
      failedLogins: null
    };
  },
  async mounted() {
    this.failedLogins = await HomeService.getFailedLogins();
  }
};