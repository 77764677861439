import "core-js/modules/es.array.push.js";
import axios from 'axios';
import { mapState } from 'vuex';
import dateTimeService from '@/services/cal/dateTimeService';
export default {
  data: () => ({
    customerFullTextSearchUrl: '/v1/dashboard/search',
    searchInputSync: '',
    searchOutputsearchLoading: false,
    searchOutputs: [],
    dateTimeService
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    })
  },
  watch: {
    searchInputSync(newValue) {
      if (newValue === '') {
        this.searchOutputs = [];
      }
      this.searchTrigger();
    }
  },
  methods: {
    initiateSearchUI() {
      this.searchOutputsearchLoading = true;
      this.searchOutputs = [];
    },
    searchFullText(query) {
      if (query == undefined) return;
      this.initiateSearchUI();
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      this.cancelToken = axios.CancelToken.source();
      axios.get(this.customerFullTextSearchUrl, {
        params: {
          q: query
        },
        cancelToken: this.cancelToken.token
      }).then(response => {
        this.searchOutputs = response.data;
        this.searchSuccessfull = true;
        this.searchOutputsearchLoading = false;
      }).catch(err => {
        console.log('error searching address: ' + err);
      });
    },
    searchTrigger() {
      if (this.searchInputSync != null && this.searchInputSync != '' && String(this.searchInputSync).length > 2) {
        this.searchStartedOnce = true;
        this.searchFullText(this.searchInputSync);
      }
    },
    openSearchItem(item) {
      switch (item.type) {
        case 'ADDRESS':
          this.$router.push({
            name: 'crmAddress',
            params: {
              lfdnr: item.id
            }
          });
          break;
        case 'DOCUMENT':
          this.$router.push({
            name: 'crmAddressDocumentDetailed',
            params: {
              lfdnr: item.object.addressLfdnr,
              wf_cid: item.object.wf_cid
            }
          });
          break;
        case 'ARTICLE':
          this.$router.push({
            name: 'Artikeldetails',
            params: {
              articleNumber: item.id
            }
          });
          break;
        default:
          alert('Dieses Feature muss noch implementiert werden.');
      }
    },
    displayItem(item) {
      console.log(item);
    }
  }
};