import axios from 'axios'

export default {
  getAddress(lfdnr) {
    return axios.get(`/v1/crm/addresses/${lfdnr}`)
  },

  loadAddressTypes() {
    //for displaying addresstypes in add address
    //includes fldName field
    const promise = axios.get(`/v1/crm/addresses/new/addresstypes`)
    return promise.then(response => response.data)
  },

  getContactData(lfdnr) {
    return axios.get(`/v1/crm/addresses/${lfdnr}/contactData`)
  },

  updateContactData(lfdnr, contactData) {
    const promise = axios.put(
      `/v1/crm/addresses/${lfdnr}/contactData`,
      contactData
    )
    return promise.then(response => response.data)
  },
  searchDoctors(searchobject) {
    const promise = axios.get('/v1/crm/addresses/new/doctorSearch', {
      params: {
        q: searchobject
      }
    })

    return promise.then(response => response.data)
  },
  searchAddressesFiltered(searchobject) {
    const promise = axios.post(
      `/v1/crm/addresses/search/filtered`,
      searchobject
    )
    return promise.then(response => response.data)
  },

  saveNewPerson(personObject) {
    const promise = axios.post(`/v1/crm/addresses/new/person`, personObject)
    return promise
  },

  saveNewNonPerson(nonPersonObject) {
    const promise = axios.post(`/v1/crm/addresses/new`, nonPersonObject)
    return promise
  },

  getAddressTypes() {
    const promise = axios.get(`/v1/crm/addresses/types`)
    return promise.then(response => response.data)
  },

  getFields() {
    const promise = axios.get(`/v1/crm/addresses/fields`)
    return promise.then(response => response.data)
  },

  getCustomerGroups() {
    const promise = axios.get(`/v1/crm/addresses/groups`)
    return promise.then(response => response.data)
  },

  loadAddAddressOptions() {
    const promise = axios.get(`/v1/crm/addresses/new/loadOptions`)
    return promise.then(response => response.data)
  },

  loadPatientOptions() {
    const promise = axios.get(`/v1/crm/addresses/new/loadOptions/patient`)
    return promise.then(response => response.data)
  },

  loadEmployeeOptions() {
    const promise = axios.get('/v1/crm/addresses/new/loadOptions/employee')
    return promise.then(response => response.data)
  },

  loadDoctorOptions() {
    const promise = axios.get('/v1/crm/addresses/new/loadOptions/doctor')
    return promise.then(response => response.data)
  },

  loadCompanyOptions() {
    const promise = axios.get('/v1/crm/addresses/new/loadOptions/company')
    return promise.then(response => response.data)
  },

  loadSupplierOptions() {
    const promise = axios.get('/v1/crm/addresses/new/loadOptions/supplier')
    return promise.then(response => response.data)
  },
  loadHealthInsuranceCompanyOptions() {
    const promise = axios.get(
      '/v1/crm/addresses/new/loadOptions/healthInsuranceCompany'
    )
    return promise.then(response => response.data)
  },
  checkForDuplicatePersons(address) {
    const promise = axios.post(
      '/v1/crm/addresses/new/duplicate/person',
      address
    )
    return promise.then(response => response.data)
  },
  checkForDuplicateNonPersons(address) {
    const promise = axios.post('/v1/crm/addresses/new/duplicate', address)
    return promise.then(response => response.data)
  },
  getAddressTypeDetailed(addressType) {
    let type = {
      icon: 'mdi-help',
      name: addressType?.fldDisplayBez ?? 'Kein Adresstyp definiert'
    }

    switch (addressType?.bezeichnung ?? 'undef') {
      case 'Unternehmen':
        type.icon = 'fas fa-building'
        break
      case 'Lieferant':
        type.icon = 'mdi-dolly'
        break
      case 'Mitarbeiter':
        type.icon = 'mdi-cash-register'
        break
      case 'Arzt':
        type.icon = 'fas fa-user-md'
        break
      case 'Krankenkasse':
        type.icon = 'fas fa-hospital-user'
        break
      case 'Patient':
        type.icon = 'fas fa-user-injured'
        break
      case 'Interessent':
        type.icon = 'mdi-account-alert'
        break
      case 'Konsument':
        type.icon = 'fas fa-user'
        break
      case 'Sachwalter':
        type.icon = 'fas fa-handshake'
        break
      case 'Heime':
        type.icon = 'fas fa-home'
        break
      default:
        type.name = 'Kein Adresstyp definiert'
    }

    return type
  },

  getDefaultValues() {
    const promise = axios.get(`v1/crm/addresses/new/loadOptions`)
    return promise.then(response => response.data)
  },

  getCountries() {
    const promise = axios.get(`v1/util/country`)
    return promise.then(response => response.data)
  },

  getCityForZip(zip, country) {
    const requestBody = {
      plz: zip,
      country
    }
    const promise = axios.post(`v1/util/autofill/city`, requestBody)
    return promise.then(response => response.data)
  },

  getStreetNames(zip, query) {
    const requestBody = {
      plz: zip,
      query: query
    }
    const promise = axios.post(`v1/util/autofill/street`, requestBody)
    return promise.then(response => response.data)
  },

  getCountryNameForId(countries, id) {
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].id === id) {
        return countries[i].description
      }
    }
  }
}
