export default {
  props: {
    on: Object,
    buttonType: {
      type: String,
      default: 'default'
    },
    buttonStyle: {
      type: String,
      default: 'default'
    },
    buttonSize: {
      type: String,
      default: 'default'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {},
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clicked(event) {
      this.$emit('click', event);
    }
  }
};