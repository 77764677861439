import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm$appointment$addre, _vm$appointment$addre2, _vm$appointment$objec;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "700"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        _vm.editAppointment = false;
      }
    },
    model: {
      value: _vm.editAppointment,
      callback: function ($$v) {
        _vm.editAppointment = $$v;
      },
      expression: "editAppointment"
    }
  }, [_c(VForm, {
    ref: "form",
    model: {
      value: _vm.formValid,
      callback: function ($$v) {
        _vm.formValid = $$v;
      },
      expression: "formValid"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "ma-0 pa-0"
  }, [_c(VToolbar, {
    staticClass: "primary--text border-bottom-primary-4",
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "top": "",
      "flat": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.editAppointment = false;
      }
    }
  }, [_vm._v("mdi-close")]), _vm.type == 'edit' ? _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Termin bearbeiten")]) : _vm._e(), _vm.type == 'add' ? _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Termin erstellen")]) : _vm._e(), _vm.type == 'show' ? _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Termindetails")]) : _vm._e()], 1)], 1), _c(VCardText, {
    staticClass: "pt-2"
  }, [_c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Beschreibung"
    },
    model: {
      value: _vm.appointment.title,
      callback: function ($$v) {
        _vm.$set(_vm.appointment, "title", $$v);
      },
      expression: "appointment.title"
    }
  })], 1)], 1), _c(VExpansionPanels, {
    attrs: {
      "multiple": "",
      "flat": ""
    },
    model: {
      value: _vm.expansionPanelModel,
      callback: function ($$v) {
        _vm.expansionPanelModel = $$v;
      },
      expression: "expansionPanelModel"
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "border-left-primary-3"
  }, [_c('h4', [_vm._v("Datum und Uhrzeit")])]), _c(VExpansionPanelContent, [_c(VCheckbox, {
    attrs: {
      "label": "Ganztägig",
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.recalculateDateTime('allDay');
      }
    },
    model: {
      value: _vm.appointment.allDay,
      callback: function ($$v) {
        _vm.$set(_vm.appointment, "allDay", $$v);
      },
      expression: "appointment.allDay"
    }
  }), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "readonly": "",
      "label": "Beginn",
      "value": this.startDateString
    },
    on: {
      "click": function ($event) {
        return _vm.openDateTimePicker('start');
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-calendar-clock")])], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "readonly": "",
      "label": "Ende",
      "value": this.endDateString
    },
    on: {
      "click": function ($event) {
        return _vm.openDateTimePicker('end');
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-calendar-clock")])], 1)], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c(VSelect, {
    attrs: {
      "suffix": "Tage",
      "dense": "",
      "items": _vm.days,
      "disabled": _vm.type === 'show'
    },
    on: {
      "change": function ($event) {
        return _vm.recalculateDateTime('duration');
      }
    },
    model: {
      value: _vm.durationSplit.days,
      callback: function ($$v) {
        _vm.$set(_vm.durationSplit, "days", $$v);
      },
      expression: "durationSplit.days"
    }
  })], 1), !_vm.appointment.allDay ? _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c(VSelect, {
    attrs: {
      "suffix": "Stunden",
      "dense": "",
      "items": _vm.hours,
      "disabled": _vm.type === 'show'
    },
    on: {
      "change": function ($event) {
        return _vm.recalculateDateTime('duration');
      }
    },
    model: {
      value: _vm.durationSplit.hours,
      callback: function ($$v) {
        _vm.$set(_vm.durationSplit, "hours", $$v);
      },
      expression: "durationSplit.hours"
    }
  })], 1) : _vm._e(), !_vm.appointment.allDay ? _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c(VSelect, {
    attrs: {
      "suffix": "Minuten",
      "dense": "",
      "items": _vm.minutes,
      "disabled": _vm.type === 'show'
    },
    on: {
      "change": function ($event) {
        return _vm.recalculateDateTime('duration');
      }
    },
    model: {
      value: _vm.durationSplit.minutes,
      callback: function ($$v) {
        _vm.$set(_vm.durationSplit, "minutes", $$v);
      },
      expression: "durationSplit.minutes"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c(VExpansionPanel, {
    staticClass: "mt-1"
  }, [_c(VDivider), _c(VExpansionPanelHeader, {
    staticClass: "border-left-primary-3"
  }, [_c('h4', [_vm._v("Details")])]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Status",
      "items": _vm.states,
      "disabled": _vm.type === 'show',
      "dense": "",
      "item-text": "description",
      "item-value": item => item
    },
    model: {
      value: _vm.appointment.state,
      callback: function ($$v) {
        _vm.$set(_vm.appointment, "state", $$v);
      },
      expression: "appointment.state"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Bereich",
      "items": _vm.scopes,
      "dense": "",
      "disabled": _vm.type === 'show',
      "item-text": "name",
      "item-value": item => item
    },
    model: {
      value: _vm.appointment.scope,
      callback: function ($$v) {
        _vm.$set(_vm.appointment, "scope", $$v);
      },
      expression: "appointment.scope"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Terminart",
      "items": _vm.types,
      "dense": "",
      "item-text": "name",
      "disabled": _vm.type === 'show',
      "item-value": item => item
    },
    on: {
      "change": function ($event) {
        _vm.showingDefDuration = true;
      }
    },
    model: {
      value: _vm.appointment.type,
      callback: function ($$v) {
        _vm.$set(_vm.appointment, "type", $$v);
      },
      expression: "appointment.type"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Lokation",
      "items": _vm.locations,
      "dense": "",
      "disabled": _vm.type === 'show',
      "item-text": "name",
      "item-value": item => item
    },
    model: {
      value: _vm.appointment.location,
      callback: function ($$v) {
        _vm.$set(_vm.appointment, "location", $$v);
      },
      expression: "appointment.location"
    }
  })], 1)], 1), _vm.showingDefDuration ? _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "3",
      "lg": "2",
      "xl": "2"
    }
  }), _c(VCol, {
    attrs: {
      "cols": "2",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VBtn, {
    staticClass: "mt-n10",
    attrs: {
      "small": "",
      "justify-center": "",
      "disabled": _vm.appointment.type.defDuration == 0
    },
    on: {
      "click": _vm.setDefDuration
    }
  }, [_vm._v("Dauer übernehmen (" + _vm._s(_vm.appointment.type.defDuration) + " min) ")])], 1)], 1) : _vm._e()], 1)], 1), _c(VExpansionPanel, {
    staticClass: "mt-1"
  }, [_c(VDivider), _c(VExpansionPanelHeader, {
    staticClass: "border-left-primary-3"
  }, [_c('h4', [_vm._v("Personen und Objekte")])]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-3",
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "10",
      "lg": "10",
      "xl": "10"
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Adresssuche",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchAddresses.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchText,
      callback: function ($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VContainer, [_c(VBtn, {
    attrs: {
      "fab": "",
      "outlined": "",
      "color": "primary darken-2"
    },
    on: {
      "click": _vm.searchAddresses
    }
  }, [_c(VIcon, [_vm._v("mdi-account-search")])], 1)], 1)], 1)], 1), ((_vm$appointment$addre = _vm.appointment.addresses) === null || _vm$appointment$addre === void 0 ? void 0 : _vm$appointment$addre.length) != 0 ? _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VList, {
    attrs: {
      "lines": "one"
    }
  }, _vm._l(_vm.appointment.addresses, function (item) {
    return _c(VListItem, {
      key: item.key.lfdnr,
      staticClass: "pa-0"
    }, [_c(VListItemAction, [_c(VBtn, {
      attrs: {
        "fab": "",
        "icon": "",
        "small": ""
      }
    }, [_c(VIcon, {
      attrs: {
        "color": "grey darken-2",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.showPictures(item);
        }
      }
    }, [_vm._v("mdi-image-multiple")])], 1), _c(VBtn, {
      attrs: {
        "fab": "",
        "icon": "",
        "small": ""
      }
    }, [_c(VIcon, {
      attrs: {
        "color": "red darken-2",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeAddress(item);
        }
      }
    }, [_vm._v("mdi-account-remove")])], 1)], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(" " + _vm._s(item.name) + " ")]), _vm.currentDisplay !== 'xs' ? _c('v-list-item-text', {
      staticClass: "d-inline-flex flex-wrap"
    }, [_vm._v(" SVNR: " + _vm._s(item.svnr) + " "), _c('br'), _vm._v(" " + _vm._s(item.street) + ", " + _vm._s(item.plz) + " " + _vm._s(item.city) + " "), _c('br'), _vm._v(" " + _vm._s(item.tel) + " "), _c('br'), _vm._v(" " + _vm._s(item.email) + " ")]) : _vm._e()], 1)], 1);
  }), 1)], 1)], 1) : _vm._e(), ((_vm$appointment$addre2 = _vm.appointment.addresses) === null || _vm$appointment$addre2 === void 0 ? void 0 : _vm$appointment$addre2.length) != 0 ? _c(VRow, {
    staticClass: "mt-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VBtn, {
    staticClass: "mr-4",
    attrs: {
      "outlined": "",
      "color": "grey darken-2",
      "rounded": "",
      "elevation": "0",
      "disabled": _vm.type === 'show'
    },
    on: {
      "click": _vm.openObjectList
    }
  }, [_c(VIcon, [_vm._v("mdi-package-variant-closed")]), _vm._v("Objekte")], 1)], 1)], 1) : _vm._e(), ((_vm$appointment$objec = _vm.appointment.objects) === null || _vm$appointment$objec === void 0 ? void 0 : _vm$appointment$objec.length) != 0 ? _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "10",
      "xl": "10"
    }
  }, [_c(VList, {
    attrs: {
      "lines": "one"
    }
  }, _vm._l(_vm.appointment.objects, function (item) {
    return _c(VListItem, {
      key: item.key.code
    }, [_c(VListItemContent, {
      staticClass: "overflow-visible"
    }, [_c(VListItemTitle, [_vm._v(_vm._s(item.description))])], 1), _c(VSpacer), _c(VListItemIcon, [_c(VIcon, {
      attrs: {
        "color": "red darken-2",
        "small": "",
        "disabled": _vm.type === 'show'
      },
      on: {
        "click": function ($event) {
          return _vm.removeObject(item);
        }
      }
    }, [_vm._v("mdi-package-variant-closed-minus")])], 1)], 1);
  }), 1)], 1)], 1) : _vm._e(), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Ressourcen",
      "items": _vm.resources,
      "dense": "",
      "item-text": "name",
      "disabled": _vm.type === 'show',
      "chips": "",
      "deletable-chips": "",
      "multiple": "",
      "item-value": item => item
    },
    model: {
      value: _vm.appointment.resources,
      callback: function ($$v) {
        _vm.$set(_vm.appointment, "resources", $$v);
      },
      expression: "appointment.resources"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c(VCardActions, [_vm.type == 'edit' ? _c(VRow, {
    staticClass: "ma-0"
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VContainer, {
    staticClass: "pa-0 d-flex justify-space-around"
  }, [_c(VBtn, {
    staticClass: "white--text",
    attrs: {
      "outlined": "",
      "color": "success",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.edit(), _vm.unlockAppointment();
      }
    }
  }, [_vm._v("Speichern")])], 1)], 1)], 1) : _vm._e(), _vm.type == 'add' ? _c(VRow, {
    staticClass: "ma-0 d-flex justify-space-around"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "6",
      "lg": "6"
    }
  }, [_c(VBtn, {
    attrs: {
      "block": "",
      "outlined": "",
      "color": "success",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.add();
      }
    }
  }, [_vm._v("Einzeltermin Erstellen")])], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "6",
      "lg": "6"
    }
  }, [_vm.type == 'add' ? _c(VBtn, {
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.openSerialAppointmentDialog();
      }
    }
  }, [_vm._v("Terminserie erstellen")]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _c('AddressSearch', {
    ref: "addressSearch",
    on: {
      "addAddress": _vm.addAddress
    }
  }), _c('ObjectList', {
    ref: "objectList",
    on: {
      "addObject": _vm.addObject
    }
  }), _c('Loading', {
    ref: "loading"
  }), _c('DateTimePicker', {
    ref: "startPicker",
    on: {
      "save": _vm.saveStart
    }
  }), _c('DateTimePicker', {
    ref: "endPicker",
    on: {
      "save": _vm.saveEnd
    }
  }), _vm.showAddressPicturesDialog ? _c('AddressPicturesDialog', {
    attrs: {
      "address": _vm.currentAddress,
      "opened": true
    },
    on: {
      "close-dialog": function ($event) {
        return _vm.closeAddressPicturesDialog();
      }
    }
  }) : _vm._e(), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  }), _c('ReservedDataDialog', {
    ref: "reserved",
    on: {
      "addWithOption": _vm.add,
      "editWithOption": _vm.edit,
      "closeParent": _vm.close
    }
  }), _c('SerialAppointmentDialog', {
    ref: "serial",
    on: {
      "close": _vm.close
    }
  }), _c('SerialReservedDataDialog', {
    ref: "reservedSerial",
    on: {
      "close": _vm.closeSerialReservedDataDialog
    }
  }), _c('ForceUpdateDialog', {
    ref: "forceUpdate",
    on: {
      "close": _vm.close
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };