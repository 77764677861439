import Header from './components/core/Header';
import NavBar from './components/core/NavBar.vue';
import Footer from './components/core/Footer';
import { mapGetters } from 'vuex';
//import BreadCrumbComponent from '@/components/core/BreadCrumbComponent.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    NavBar
    //BreadCrumbComponent
  },
  data: () => ({
    settings: {
      default: null
    },
    drawer: false
  }),
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    drawerChange(newVal) {
      this.drawer = newVal;
    }
  },
  computed: {
    ...mapGetters(['loggedIn'])
  },
  mounted() {
    // falls User die Seite refreshed und ein Token gespeichert ist, wird dieser Token für zukünftige requests benutzt
    this.$store.dispatch('loadAuthFromLocalStorage');

    // wurde vorerst deaktiviert, da nicht klar ist ob überhaupt funktional
    /*const checkAppVersionAndClearCache = async () => {
      const getVersionFromCache = async () => {
        return new Promise(resolve => {
          const cachedVersion = localStorage.getItem('appVersion')
          resolve(cachedVersion)
        })
      }
       const getCurrentVersion = async () => {
        return new Promise(resolve => {
          const currentVersion = process.env.VUE_APP_GIT_HASH || 'n/a'
          resolve(currentVersion)
        })
      }
       const clearBrowserCache = async () => {
        return new Promise(resolve => {
          if ('caches' in window) {
            caches.keys().then(cacheNames => {
              cacheNames.forEach(cacheName => {
                caches.delete(cacheName)
              })
              resolve()
            })
          } else {
            resolve()
          }
        })
      }
       const updateAppVersionInCache = async version => {
        return new Promise(resolve => {
          localStorage.setItem('appVersion', version)
          resolve()
        })
      }
       const cachedVersion = await getVersionFromCache()
      const currentVersion = await getCurrentVersion()
       if (cachedVersion !== currentVersion) {
        await clearBrowserCache()
        await updateAppVersionInCache(currentVersion)
      }
    }
     checkAppVersionAndClearCache()
    */
    //this.$store.dispatch('loadUserSettings')
  }
};