import axios from 'axios'

export default {
  async saveWidgetLayout(responsiveLayouts) {
    const response = await axios.put('/v1/dashboard', responsiveLayouts)

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getWidgetLayout() {
    const response = await axios.get('/v1/dashboard')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getAvailableWidgets() {
    const response = await axios.get('/v1/dashboard/widgets')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  /* Specific Widget Functions */

  async getAppointments() {
    const response = await axios.get('/v1/calv2/dashboard/appointments')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getCustomersToday() {
    const response = await axios.get('/v1/statistics/dashboard/customer/today')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getDocumentsLast() {
    const response = await axios.get('/v1/crm/dashboard/documents/last')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getInteractionsLast() {
    const response = await axios.get('/v1/crm/dashboard/interactions/last')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getOpenPositions() {
    const response = await axios.get('/v1/statistics/dashboard/openPositions')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getTasks() {
    const response = await axios.get('/v1/task/dashboard/tasks')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getFailedLogins() {
    const response = await axios.get('/v1/dev/dashboard/telemetry/failedLogins')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async setStatisticFilter(filter) {
    const response = await axios.post(
      '/v1/statistics/revenue/filter/comparison',
      filter
    )

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getYearData() {
    const response = await axios.get('/v1/statistics/revenue/year')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getMonthData(year) {
    const response = await axios.get('/v1/statistics/revenue/year/month', {
      params: {
        year: year
      }
    })

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },
}
