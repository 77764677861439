import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "367px"
    },
    model: {
      value: _vm.DateTimePicker,
      callback: function ($$v) {
        _vm.DateTimePicker = $$v;
      },
      expression: "DateTimePicker"
    }
  }, [_c(VCard, {
    attrs: {
      "flat": ""
    }
  }, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0 elevation-0",
    attrs: {
      "color": "primary",
      "flat": "",
      "fixed": "",
      "top": "",
      "dark": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.DateTimePicker = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Datum und Uhrzeit wählen")])], 1)], 1), _c(VTabs, {
    attrs: {
      "fixed-tabs": "",
      "icons-and-text": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTab, {
    key: "date",
    attrs: {
      "default": ""
    }
  }, [_vm._v(_vm._s(_vm.dateString)), _c(VIcon, [_vm._v("mdi-calendar-range")])], 1), _c(VTab, {
    key: "time",
    attrs: {
      "disabled": _vm.allDay
    }
  }, [_vm._v(_vm._s(_vm.time) + " "), _c(VIcon, [_vm._v("mdi-clock")])], 1)], 1), _c(VCardText, {
    staticClass: "mx-0 px-0 my-3 py-0",
    attrs: {
      "justify-center": "",
      "align": "center"
    }
  }, [_c(VTabsItems, {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTabItem, {
    key: "date"
  }, [_c(VDatePicker, {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "first-day-of-week": "1",
      "color": "primary",
      "min": _vm.minimumDate
    },
    on: {
      "change": function ($event) {
        return _vm.goToTime();
      }
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c(VTabItem, {
    key: "time"
  }, [_c('vue-timepicker'), _c(VRow, {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c(VCol, [_c(VTextField, {
    staticClass: "text-justify text-center",
    staticStyle: {
      "width": "55px"
    },
    attrs: {
      "maxlength": "5",
      "type": "time",
      "step": "300"
    },
    on: {
      "change": function ($event) {
        return _vm.steptime(this);
      }
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  }), _vm.blockSave ? _c(VAlert, {
    staticStyle: {
      "margin-left": "10px",
      "margin-right": "10px"
    },
    attrs: {
      "type": "warning"
    }
  }, [_vm._v(" Die Uhrzeit darf nur in 5 Minuten Schritten gespeichert werden. "), _c(VRow, [_c(VCol, [_c(VBtn, {
    on: {
      "click": _vm.setRoundDown
    }
  }, [_vm._v(" " + _vm._s(_vm.roundToNearestFiveMinutes(this.time, 'down')) + " ")])], 1), _c(VCol, [_c(VBtn, {
    on: {
      "click": _vm.setRoundUp
    }
  }, [_vm._v(" " + _vm._s(_vm.roundToNearestFiveMinutes(this.time, 'up')) + " ")])], 1)], 1)], 1) : _vm._e()], 1)], 1), _c(VTimePicker, {
    attrs: {
      "allowed-minutes": _vm.allowedStep,
      "format": "24hr",
      "color": "primary",
      "no-title": ""
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1)], 1)], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("Fertig")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };