import FileHandler from '@/services/utils/fileHandlingService.js';
import AddressPicturesUploadDialog from '@/components/crm/addresses/AddressPicturesUploadDialog.vue';
import { mapState } from 'vuex';
import uploadService from '@/services/crm/uploadService.js';
import PictureTable from '@/components/crm/addresses/pictureUpload/PictureTable.vue';
export default {
  components: {
    AddressPicturesUploadDialog,
    PictureTable
  },
  props: {
    address: Object,
    appointmentKey: Object
  },
  computed: {
    ...mapState({
      addressPictures: state => state.calendarInformation.addressPictures
    })
  },
  data: () => ({
    search: '',
    opened: true,
    showPictureUploadDialog: false,
    tab: null,
    loadingGeneralData: true,
    picTypes: ['Adresse', 'Datenblätter', 'Trittspuren', 'VOS', 'Dokumente'],
    images: []
  }),
  methods: {
    loadPicTypes() {
      uploadService.getPictureTypes().then(picTypes => {
        console.log('Pictypes');
        console.log(picTypes);
        this.picTypes = picTypes;
      }).finally(() => this.loadingGeneralData = false);
    },
    async loadAddressPictures(address) {
      var _address$lfdnr;
      console.log(this.$store);
      await this.$store.dispatch('loadPicturesForAddress', {
        firma: 1,
        lfdnr: (_address$lfdnr = address.lfdnr) !== null && _address$lfdnr !== void 0 ? _address$lfdnr : address.key.lfdnr
      });
      this.images = FileHandler.getAddressPicturesArray(this.addressPictures);
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
    closeAddressPicturesUploadDialog() {
      this.showPictureUploadDialog = false;
    }
  },
  mounted() {
    console.warn(this.address);
    this.loadPicTypes();
    this.loadAddressPictures(this.address);
  }
};