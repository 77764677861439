import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import HomeService from '@/services/home/homeService.js';
import dateTimeService from '@/services/cal/dateTimeService.js';
import EditAppointment from '@/components/calendar_v2/EditAppointment.vue';
export default {
  computed: {
    ...mapState({
      appointmentDetails: state => state.calAppointments.appointment,
      scopes: state => state.calendarInformation.scopes,
      locations: state => state.calendarInformation.locations,
      types: state => state.calendarInformation.types,
      states: state => state.calendarInformation.states
    })
  },
  components: {
    EditAppointment
  },
  data: function () {
    return {
      appointments: null
    };
  },
  methods: {
    formatAppointmentRow(event, currentDateInput) {
      let start = new Date(event.start);
      let end = new Date(event.end);
      let currentDate = new Date(currentDateInput);
      let outputString = '';

      // Check if the startdate of the event is today, if it is, add the events starttime to the outputString
      if (start.getFullYear() == currentDate.getFullYear() && start.getMonth() == currentDate.getMonth() && start.getDate() == currentDate.getDate()) {
        outputString += DateTime.fromJSDate(new Date(start)).toFormat('HH:mm') + ' ';
      }

      // Check if the enddate of the event is today, if it is, add the events starttime to the outputString
      if (end.getFullYear() == currentDate.getFullYear() && end.getMonth() == currentDate.getMonth() && end.getDate() == currentDate.getDate()) {
        outputString += ' - ' + DateTime.fromJSDate(new Date(end)).toFormat('HH:mm') + ' ';
      }

      // Adds the event title to the output string
      outputString += ` ${event.title}`;

      // add firstAddressKdSortname if it exists
      if (event.firstAddressKdSortname != null) {
        outputString += `: ${event.firstAddressKdSortname}`;
      }

      // if an event last multiple days, this will include an Day 1/15
      if (start.getFullYear() != end.getFullYear() || start.getMonth() != end.getMonth() || start.getDate() != end.getDate()) {
        let diffStartEnd = this.daysBetween(start, end) + 1;
        let diffCurrentStart = this.daysBetween(start, currentDate) + 1;
        outputString += ` (Tag ${diffCurrentStart}/${diffStartEnd})`;
      }
      return outputString;
    },
    // returns how many days are between two dates as an integer
    daysBetween(first, second) {
      // Copy date parts of the timestamps, discarding the time parts.
      var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
      var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

      // Do the math.
      var millisecondsPerDay = 1000 * 60 * 60 * 24;
      var millisBetween = two.getTime() - one.getTime();
      var days = millisBetween / millisecondsPerDay;

      // Round down.
      return second > first ? Math.floor(days) : Math.ceil(days);
    },
    styleDate(date) {
      return dateTimeService.getLocalTimeWithDate(new Date(date));
    },
    getTimeOfDate(date) {
      return DateTime.fromJSDate(new Date(date)).toFormat('HH:mm');
    },
    openAppointment(appointment) {
      if (this.scopes === undefined) {
        this.$store.dispatch('loadScopes');
      }
      if (this.locations === undefined) {
        this.$store.dispatch('loadLocations');
      }
      if (this.states === undefined) {
        this.$store.dispatch('loadStates');
      }
      if (this.types === undefined) {
        this.$store.dispatch('loadTypes');
      }
      this.$store.dispatch('loadAppointment', appointment.key).then(() => {
        this.$refs.editAppointment.appointment = this.appointmentDetails;
        this.$refs.editAppointment.open('show', '', '');
      });
    }
  },
  async mounted() {
    this.appointments = await HomeService.getAppointments();
  }
};