import HomeService from '@/services/home/homeService.js';
import dateTimeService from '@/services/cal/dateTimeService';
export default {
  data() {
    return {
      documents: [],
      dateTimeService
    };
  },
  async mounted() {
    this.documents = await HomeService.getDocumentsLast();
  }
};