import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "persistent": "",
      "max-width": "650px"
    },
    model: {
      value: _vm.reservedDataDialog,
      callback: function ($$v) {
        _vm.reservedDataDialog = $$v;
      },
      expression: "reservedDataDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "warning",
      "fixed": "",
      "top": "",
      "dark": "",
      "flat": ""
    }
  }, [_c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Terminkonflikt")])], 1)], 1), _c(VCardText, {
    staticClass: "mx-0 px-0 my-3 py-0",
    attrs: {
      "justify-center": ""
    }
  }, [[_c('div', {
    attrs: {
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.reservedData.appointment.title))])]), _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "5.5"
    }
  }, [_c(VCard, {
    staticStyle: {
      "border": "1px solid #757575"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c(VCardText, [_c('div', [_vm.reservedData.reservedAddresses.length !== 0 ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mt-n1 ml-n1",
    attrs: {
      "dense": ""
    }
  }, [_vm._v(" mdi-account ")]), _vm._v(" Adressen "), _vm._l(_vm.reservedData.reservedAddresses, function (a) {
    return _c('span', {
      key: a.key.lfdnr,
      staticClass: "text-decoration-line-through red--text class font-weight-regular"
    }, [_c('br'), _vm._v(" " + _vm._s(a.name) + " ")]);
  })], 2) : _vm._e(), _vm.reservedData.reservedResources.length !== 0 ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mt-n1 ml-n1",
    attrs: {
      "dense": ""
    }
  }, [_vm._v(" mdi-account-tie ")]), _vm._v(" Ressourcen ")], 1) : _vm._e(), _vm._l(_vm.reservedData.reservedResources, function (r) {
    return _c('span', {
      key: r.key.lfdnr,
      staticClass: "text-decoration-line-through red--text class font-weight-regular"
    }, [_c('br'), _vm._v(" " + _vm._s(r.name) + " ")]);
  }), _vm.reservedData.reservedObjects.length !== 0 ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mt-n1 ml-n1",
    attrs: {
      "dense": ""
    }
  }, [_vm._v(" mdi-package-variant-closed ")]), _vm._v(" Objekte "), _vm._l(_vm.reservedData.reservedObjects, function (o) {
    return _c('span', {
      key: o.key.lfdnr,
      staticClass: "text-decoration-line-through red--text class font-weight-regular"
    }, [_c('br'), _vm._v(" " + _vm._s(o.description) + " ")]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c(VBtn, {
    staticClass: "orange--text d-flex justify-center",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.perform('CREATE_WITHOUT');
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2 ml-n2"
  }, [_vm._v(" mdi-close ")]), _vm._v(" Kollision entfernen ")], 1)], 1)], 2)])], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "5.5"
    }
  }, [_c(VCard, {
    staticStyle: {
      "border": "1px solid #757575"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c(VCardText, [_c('div', [_vm.reservedData.reservedAddresses.length !== 0 ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mt-n1 ml-n1",
    attrs: {
      "dense": ""
    }
  }, [_vm._v(" mdi-account ")]), _vm._v(" Adressen "), _vm._l(_vm.reservedData.reservedAddresses, function (a) {
    return _c('span', {
      key: a.key.lfdnr,
      staticClass: "font-weight-regular"
    }, [_c('br'), _vm._v(" " + _vm._s(a.name) + " ")]);
  })], 2) : _vm._e(), _vm.reservedData.reservedResources.length !== 0 ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mt-n1 ml-n1",
    attrs: {
      "dense": ""
    }
  }, [_vm._v(" mdi-account-tie ")]), _vm._v(" Ressourcen ")], 1) : _vm._e(), _vm._l(_vm.reservedData.reservedResources, function (r) {
    return _c('span', {
      key: r.key.lfdnr,
      staticClass: "font-weight-regular"
    }, [_c('br'), _vm._v(" " + _vm._s(r.name) + " ")]);
  }), _vm.reservedData.reservedObjects.length !== 0 ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mt-n1 ml-n1",
    attrs: {
      "dense": ""
    }
  }, [_vm._v(" mdi-package-variant-closed ")]), _vm._v(" Objekte "), _vm._l(_vm.reservedData.reservedObjects, function (o) {
    return _c('span', {
      key: o.key.lfdnr,
      staticClass: "font-weight-regular"
    }, [_c('br'), _vm._v(" " + _vm._s(o.description) + " ")]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c(VBtn, {
    staticClass: "orange--text",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.perform('CREATE');
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2 ml-n2"
  }, [_vm._v(" mdi-check ")]), _vm._v(" Kollision Übernehmen ")], 1)], 1)], 2)])], 1)], 1)], 1)], 1)]], 2), _c(VCardActions, {
    staticClass: "pb-4 mt-n4"
  }, [_c(VBtn, {
    staticClass: "flex justify-content-center orange--text",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2 ml-n2"
  }, [_vm._v(" mdi-pencil ")]), _vm._v(" Termin bearbeiten ")], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };