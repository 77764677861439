import { DateTime } from 'luxon';
export default {
  data() {
    return {
      time: '',
      date: '',
      interval: null
    };
  },
  methods: {
    updateTime() {
      var jsDate = new Date();
      this.time = DateTime.fromJSDate(jsDate).toFormat('HH:mm') + ' ';
      this.date = DateTime.fromJSDate(jsDate).toFormat('DDDD') + ' ';
    }
  },
  mounted() {
    this.updateTime();
    this.interval = setInterval(() => {
      this.updateTime();
    }, 35000);
  },
  destroyed() {
    clearInterval(this.interval);
  }
};