import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "880px"
    },
    model: {
      value: _vm.ObjectList,
      callback: function ($$v) {
        _vm.ObjectList = $$v;
      },
      expression: "ObjectList"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.ObjectList = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Objekte")])], 1)], 1), _c(VCardText, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VSimpleTable, [_c('tbody', [_c(VDataTable, {
    ref: "vuetable",
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.header,
      "items": _vm.objects,
      "items-per-page": 10
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Daten gefunden. ")];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c(VBtn, {
          staticClass: "mr-2 col-xl-5",
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "elevation": "2"
          },
          on: {
            "click": function ($event) {
              return _vm.addObject(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-plus ")])], 1)];
      }
    }])
  }, [_vm._v(" >")])], 1)])], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.ObjectList = false;
      }
    }
  }, [_vm._v("Fertig")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };