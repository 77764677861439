var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "98%",
      "options": _vm.options,
      "series": _vm.series
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };