import "core-js/modules/es.array.push.js";
import axios from 'axios';
export default {
  data: function () {
    return {
      series: [],
      filter: {
        startYear: '',
        endYear: '',
        startMonth: '',
        endMonth: '',
        toCurrentMonth: true,
        wj: '',
        db: '',
        locations: [],
        states: [],
        articleTypes: [],
        articleCategories: [],
        articleGroups: []
      },
      selectedMonth: '',
      selectedYear: '',
      months: ['Jan.', 'Feb.', 'März', 'Apr.', 'Mai', 'Jun.', 'Jul.', 'Aug.', 'Sept.', 'Okt', 'Nov.', 'Dez.']
    };
  },
  computed: {
    options: function () {
      return {
        dataLabels: {
          enabled: false
        },
        title: {},
        states: {
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        xaxis: {
          labels: {
            formatter: value => {
              switch (value) {
                case '1':
                  return 'Jan.';
                case '2':
                  return 'Feb.';
                case '3':
                  return 'März';
                case '4':
                  return 'Apr.';
                case '5':
                  return 'Mai';
                case '6':
                  return 'Jun.';
                case '7':
                  return 'Jul.';
                case '8':
                  return 'Aug.';
                case '9':
                  return 'Sept.';
                case '10':
                  return 'Okt.';
                case '11':
                  return 'Nov.';
                case '12':
                  return 'Dez.';
                default:
                  return value;
              }
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              });
              return form.format(value);
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        }
      };
    }
  },
  mounted() {
    this.setDefaultFilter();
  },
  methods: {
    setDefaultFilter() {
      var temp = new Date();
      var temptemp = temp.getFullYear() - 1;
      var from = temptemp.toString();
      var currentMonthInt = temp.getMonth() - 2;
      this.selectedMonth = this.getMonthString(currentMonthInt);
      this.filter.startMonth = this.getMonthString(currentMonthInt);
      this.filter.startYear = from;
      this.$emit('setDefaultValues', this.filter.startMonth, this.filter.startYear);
      this.loadData();
    },
    loadData() {
      axios.post(`v1/statistics/revenue/month`, this.filter).then(data => {
        var seriesData = [];
        var years = [...new Set(data.data.map(item => item.year))];
        var months = [...new Set(data.data.map(item => item.month))];
        years.forEach(year => {
          var yearData = data.data.filter(item => item.year === year);
          var series = {
            name: year,
            data: []
          };

          // Populate series data with revenue for each month
          months.forEach(month => {
            var monthData = yearData.find(item => item.month === month);
            if (monthData) {
              series.data.push({
                x: monthData.month + '',
                y: monthData.revenue
              });
            } else {
              // If data is missing for a month in a year, set y-value to 0
              series.data.push({
                x: month + '',
                y: 0
              });
            }
          });
          seriesData.push(series);
        });
        this.isYearSelected = true;
        this.series = seriesData;
        console.warn(this.series);
      });
    },
    getMonthString(month) {
      switch (month) {
        case 0:
          return 'Jan.';
        case 1:
          return 'Feb.';
        case 2:
          return 'März';
        case 3:
          return 'Apr.';
        case 4:
          return 'Mai';
        case 5:
          return 'Jun.';
        case 6:
          return 'Jul.';
        case 7:
          return 'Aug.';
        case 8:
          return 'Sept.';
        case 9:
          return 'Okt.';
        case 10:
          return 'Nov.';
        case 11:
          return 'Dez.';
      }
    }
  }
};