import HomeService from '@/services/home/homeService.js';
export default {
  data() {
    return {
      customersToday: null
    };
  },
  async mounted() {
    this.customersToday = await HomeService.getCustomersToday();
  }
};