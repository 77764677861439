var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "centerElement"
  }, [_c('p', {
    staticClass: "pt-2 text-h2 font-weight-bold text-center"
  }, [_vm._v(" " + _vm._s(_vm.time) + " ")]), _c('p', {
    staticClass: "text-h5 text-center"
  }, [_vm._v(_vm._s(_vm.date))])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };