import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VNavigationDrawer, {
    staticClass: "elevation-0 d-flex flex-column",
    attrs: {
      "app": "",
      "permanent": !_vm.miniNavbar,
      "temporary": _vm.miniNavbar,
      "mobile-breakpoint": "md"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c(VDivider, {
          staticClass: "mx-4"
        }), _c(VContainer, {
          staticClass: "d-flex flex-column align-center my-2"
        }, [_c('VersionChip'), _c(VExpansionPanels, {
          staticClass: "pt-4 text-center"
        }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
          staticClass: "text-center primary--text",
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "color": "primary"
                }
              }, [_vm._v(" $expand ")])];
            },
            proxy: true
          }])
        }, [_vm._v(" " + _vm._s(_vm.company.name) + " ")]), _c(VExpansionPanelContent, {
          staticClass: "primary--text"
        }, [_c('div', {
          staticClass: "ma-1"
        }, [_vm._v(" " + _vm._s(_vm.company.postcode) + " " + _vm._s(_vm.company.city) + ", " + _vm._s(_vm.company.country) + " ")])])], 1)], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isNavbarOpen,
      callback: function ($$v) {
        _vm.isNavbarOpen = $$v;
      },
      expression: "isNavbarOpen"
    }
  }, [_c('div', [_c(VList, {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_c(VListItemGroup, {
    model: {
      value: _vm.group,
      callback: function ($$v) {
        _vm.group = $$v;
      },
      expression: "group"
    }
  }, [_c(VListItem, {
    staticClass: "mx-3 pa-2 my-5",
    attrs: {
      "to": {
        name: 'Home'
      },
      "color": "white"
    }
  }, [_c('img', {
    staticClass: "pa-3",
    staticStyle: {
      "max-width": "150px"
    },
    attrs: {
      "src": require("@/assets/LBA-Logo-Text.svg"),
      "alt": "LBA-WEB"
    }
  })]), _c(VDivider, {
    staticClass: "mx-4"
  }), _vm.lbaApps.length != 0 ? _c('div', {
    staticClass: "my-5 mx-3 pa-2"
  }, [!_vm.miniNavbar ? _c('h5', {
    staticClass: "text-uppercase pb-2 primary--text"
  }, [_vm._v(" LBA-Apps ")]) : _vm._e(), _vm._l(_vm.lbaApps, function (app, i) {
    return _c('div', {
      key: i
    }, [app.appBezeichnung !== 'Statistik' ? _c(VListItem, {
      staticClass: "primary--text li-item",
      attrs: {
        "exact": "",
        "to": app.appUrl,
        "disabled": !app.hasPermission,
        "active-class": "selected-li-item"
      }
    }, [_c(VListItemAvatar, [_c('img', {
      attrs: {
        "src": _vm.appImg(app)
      }
    })]), _c(VListItemContent, [_c(VListItemTitle, {
      domProps: {
        "textContent": _vm._s(app.appBezeichnung)
      }
    })], 1)], 1) : _vm._e(), app.appBezeichnung === 'Statistik' ? _c(VListGroup, {
      staticClass: "primary--text",
      attrs: {
        "active-class": "primary--text"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c(VListItemAvatar, [_c('img', {
            attrs: {
              "src": _vm.appImg(app)
            }
          })]), _c(VListItemContent, [_c(VListItemTitle, {
            staticClass: "primary--text",
            domProps: {
              "textContent": _vm._s(app.appBezeichnung)
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c(VListItem, {
      staticClass: "primary--text li-item pl-6",
      attrs: {
        "to": '/bi/revenue',
        "active-class": "selected-li-item"
      }
    }, [_c(VListItemAvatar, [_c('img', {
      attrs: {
        "src": _vm.appImg(app)
      }
    })]), _c(VListItemContent, [_c(VListItemTitle, {
      domProps: {
        "textContent": _vm._s('Umsatz')
      }
    })], 1)], 1), _c(VListItem, {
      staticClass: "primary--text li-item pl-6",
      attrs: {
        "to": '/bi/keyfigures',
        "active-class": "selected-li-item"
      }
    }, [_c(VListItemAvatar, [_c('img', {
      attrs: {
        "src": _vm.appImg(app)
      }
    })]), _c(VListItemContent, [_c(VListItemTitle, {
      domProps: {
        "textContent": _vm._s('Kennzahlen')
      }
    })], 1)], 1)], 1) : _vm._e()], 1);
  })], 2) : _vm._e(), _c(VDivider, {
    staticClass: "mx-4"
  }), _vm.lbaSettingsApps.length != 0 ? _c('div', {
    staticClass: "my-5 mb-8 mx-3 pa-2"
  }, [!_vm.miniNavbar ? _c('h5', {
    staticClass: "text-uppercase pb-2 pl-2 primary--text"
  }, [_vm._v(" LBA-Einstellungen ")]) : _vm._e(), _vm._l(_vm.getSettingsAppsWithoutSettings(), function (app, i) {
    return _c(VListItem, {
      key: i,
      staticClass: "primary--text li-item",
      attrs: {
        "exact": "",
        "to": app.appUrl,
        "disabled": !app.hasPermission,
        "active-class": "selected-li-item"
      }
    }, [_c(VListItemAvatar, [_c('img', {
      attrs: {
        "src": _vm.appImg(app)
      }
    })]), _c(VListItemContent, [_c(VListItemTitle, {
      domProps: {
        "textContent": _vm._s(app.appBezeichnung)
      }
    })], 1)], 1);
  }), _vm.settingsAppExists() ? _c(VListGroup, {
    attrs: {
      "color": "white"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c(VListItemAvatar, [_c('img', {
          attrs: {
            "src": _vm.appImg(_vm.settingsApp)
          }
        })]), _c(VListItemContent, {
          staticClass: "primary--text"
        }, [_c(VListItemTitle, {
          domProps: {
            "textContent": _vm._s(_vm.settingsApp.appBezeichnung)
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3579600562)
  }, _vm._l(_vm.getAppsWithSettings(), function (app, i) {
    return _c(VListItem, {
      key: i,
      staticClass: "primary--text li-item pl-6",
      attrs: {
        "exact": "",
        "to": '/settings' + app.appUrl,
        "disabled": !app.hasPermission,
        "active-class": "selected-li-item"
      }
    }, [_c(VListItemAvatar, [_c('img', {
      attrs: {
        "src": _vm.appImg(app)
      }
    })]), _c(VListItemContent, [_c(VListItemTitle, {
      domProps: {
        "textContent": _vm._s(app.appBezeichnung)
      }
    })], 1)], 1);
  }), 1) : _vm._e()], 2) : _vm._e()], 1)], 1)], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };