import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.appointments !== null && _vm.appointments.all.length > 0 ? _c('div', _vm._l(_vm.appointments.all, function (item) {
    return _c(VList, {
      key: item.date,
      staticClass: "pt-0"
    }, [_c('div', {
      staticClass: "font-weight-bold text-h6 py-1 primary--text mb-2"
    }, [_vm._v(" " + _vm._s(_vm.styleDate(item.date)) + " ")]), _vm._l(item.appointments, function (event) {
      return _c(VListItem, {
        key: event.key.lfdnr,
        staticClass: "rounded ma-1",
        staticStyle: {
          "border": "1px solid #c8c6d2"
        },
        attrs: {
          "dense": ""
        },
        on: {
          "click": function ($event) {
            return _vm.openAppointment(event);
          }
        }
      }, [_c(VListItemAvatar, [_c(VAvatar, {
        attrs: {
          "color": event.scope.color,
          "size": "30"
        }
      })], 1), _c(VListItemContent, {
        staticClass: "d-flex justify-start"
      }, [_vm._v(" " + _vm._s(_vm.formatAppointmentRow(event, item.date)) + " ")])], 1);
    })], 2);
  }), 1) : _c('div', [_vm._m(0)]), _c('EditAppointment', {
    ref: "editAppointment"
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    staticClass: "pa-3 centerElement widgetNoDataPlaceholder",
    attrs: {
      "src": require("@/assets/svg/undraw_completing_re_i7ap.svg"),
      "alt": "Task-finished"
    }
  })]);
}];
export { render, staticRenderFns };