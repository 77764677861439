import "core-js/modules/es.array.push.js";
import HomeService from '@/services/home/homeService.js';
import LBAButton from '@/components/ui/buttons/LBAButton.vue';
export default {
  components: {
    LBAButton
  },
  data() {
    return {
      wj: false,
      showYear: true,
      seriesYear: [],
      selectedYear: '',
      showMonth: false,
      seriesMonth: [],
      filter: {
        startYear: '',
        endYear: '',
        toCurrentMonth: '',
        wj: '',
        db: '',
        locations: [],
        states: [],
        articleTypes: [],
        articleCategories: [],
        articleGroups: []
      }
    };
  },
  computed: {
    yearOptions: function () {
      return {
        dataLabels: {
          enabled: false
        },
        title: {},
        xaxis: {
          type: 'category'
        },
        states: {
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              let form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              });
              return form.format(value);
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          },
          events: {
            dataPointSelection: async (e, chart, config) => {
              let year = config.w.globals.seriesX[config.seriesIndex][config.dataPointIndex];
              if (year !== undefined) {
                this.selectedYear = year;
                await this.switchToMonth();
              }
            }
          }
        }
      };
    },
    monthOptions: function () {
      return {
        dataLabels: {
          enabled: false
        },
        states: {
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        xaxis: {
          labels: {
            formatter: value => {
              switch (value) {
                case '1':
                  return 'Jan.';
                case '2':
                  return 'Feb.';
                case '3':
                  return 'März';
                case '4':
                  return 'Apr.';
                case '5':
                  return 'Mai';
                case '6':
                  return 'Jun.';
                case '7':
                  return 'Jul.';
                case '8':
                  return 'Aug.';
                case '9':
                  return 'Sept.';
                case '10':
                  return 'Okt.';
                case '11':
                  return 'Nov.';
                case '12':
                  return 'Dez.';
                default:
                  return value;
              }
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              let form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              });
              return form.format(value);
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        }
      };
    }
  },
  async mounted() {
    await this.setDefaultFilter();
  },
  methods: {
    async setDefaultFilter() {
      let tempDate = new Date();
      let tempYear = tempDate.getFullYear() - 3;
      let from = tempYear.toString();
      this.filter.startYear = from;
      await HomeService.setStatisticFilter(this.filter);
      await this.loadYearData();
    },
    async changeWj() {
      this.filter.wj = this.wj;
      await HomeService.setStatisticFilter(this.filter);
      await this.loadYearData();
    },
    async loadYearData() {
      const data = await HomeService.getYearData();
      this.showMonth = false;
      this.showYear = true;
      let types = [];
      let seriesWrapper = [];
      data.forEach(element => {
        for (let prop in element.y) {
          if (!types.includes(prop)) types.push(prop);
        }
      });
      types.forEach(type => {
        let series = {
          name: '',
          data: []
        };
        let dataSeries = [];
        series.name = type;
        data.forEach(element => {
          dataSeries.push({
            x: element.x,
            y: element.y[type]
          });
        });
        series.data = dataSeries;
        seriesWrapper.push(series);
      });
      this.seriesYear = seriesWrapper;
    },
    async switchToMonth() {
      const monthData = await HomeService.getMonthData(this.selectedYear);
      this.showYear = false;
      this.showMonth = true;
      let types = [];
      let seriesWrapper = [];
      monthData.forEach(element => {
        for (let prop in element.y) {
          if (!types.includes(prop)) types.push(prop);
        }
      });
      types.forEach(type => {
        let series = {
          name: '',
          data: []
        };
        series.name = type;
        let dataSeries = [];
        monthData.forEach(element => {
          dataSeries.push({
            x: element.x,
            y: element.y[type]
          });
        });
        series.data = dataSeries;
        seriesWrapper.push(series);
      });
      this.seriesMonth = seriesWrapper;
      /*this.$store.dispatch('setWidgetLabel', {
        id: 'STATISTIC_YEAR',
        label: 'Monatsvergleich Umsatz ' + this.selectedYear
      })*/
    },
    async switchToYear() {
      await this.loadYearData();
      /*this.$store.dispatch('setWidgetLabel', {
        id: 'STATISTIC_YEAR',
        label: 'Jahresvergleich Umsatz'
      })*/
    }
  }
};