export default {
  state: {
    widgetLabels: {
      CLOCK: 'Uhrzeit'
    }
  },
  mutations: {
    SET_WIDGET_LABEL(state, options) {
      state.widgetLabels[options.id] = options.label
    }
  },
  actions: {
    setWidgetLabel({ commit }, options) {
      commit('SET_WIDGET_LABEL', options)
    }
  },
  getters: {
    widgetLabels: state => state.widgetLabels
  }
}
