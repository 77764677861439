import axios from 'axios'
import jwt from 'jsonwebtoken'
import { DateTime } from 'luxon'
import store from '@/store'
import router from '@/router'

const REFRESH_BEFORE_BEARER_EXPIRES_SECONDS = 59

export default {
  async renewBearer() {
    await axios
      .post('/v1/refresh', {
        refreshToken: this.getRefreshTokenFromLocalStorage()
      })
      .then(res => {
        store.dispatch('setAuthData', res.data)
        console.debug('File: auth.js, Msg: bearer renewed')
      })
      .catch(() => {
        console.debug('File: auth.js, Msg: FAILED to renew bearer')
        store.dispatch('reAuth')
      })
  },
  async renewBearerWithRefreshToken(refreshToken, path, query) {
    await axios
      .post('/v1/refresh', {
        refreshToken: refreshToken
      })
      .then(res => {
        store.dispatch('setAuthData', res.data)
        console.debug('File: auth.js, Msg: bearer renewed')
      })
      .catch(() => {
        console.debug('File: auth.js, Msg: FAILED to renew bearer')
        store.dispatch('reAuth')
      })

    //let query = to.query
    console.debug(query)

    delete query.refreshToken
    console.debug(query)

    router.push({ path, query })
  },

  async writeAuthToLocalStorage(auth) {
    await localStorage.setItem('auth', JSON.stringify(auth))
    try {
      let bearerExpiringTime = new DateTime.fromSeconds(
        jwt.decode(auth.bearer).exp
      )
      let jwtRenewInSeconds = Math.floor(
        bearerExpiringTime.diff(new DateTime.now(), ['seconds']).seconds -
          REFRESH_BEFORE_BEARER_EXPIRES_SECONDS
      )
      // setTimeout in Milliseconds
      setTimeout(() => {
        this.renewBearer()
      }, jwtRenewInSeconds * 1000)
    } catch {
      console.debug('authService.js: bearer not set')
    }
  },

  getBearerFromLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem('auth')).bearer
    } catch (e) {
      return null
    }
  },

  getRefreshTokenFromLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem('auth')).refreshToken
    } catch (e) {
      return null
    }
  }
}
