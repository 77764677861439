import calendarService from '@/services/cal/calendarService.js';
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    calendarService
  },
  data: () => ({
    serialReservedDataDialog: false,
    reservedData: null,
    type: '',
    text: '',
    conflicts: []
  }),
  methods: {
    open(conflicts, type) {
      this.conflicts = conflicts;
      this.type = type;
      this.serialReservedDataDialog = true;
      console.log('Opening dialog reserved serial');
      console.warn(conflicts);
    },
    async performAll(action) {
      this.$emit('close');
      for (var conflict of this.conflicts) {
        await this.perform(action, conflict);
      }
    },
    async perform(action, conflict) {
      console.log(conflict);
      let conflictCopy = JSON.parse(JSON.stringify(conflict.appointment));
      conflictCopy.conflictResolution = action;
      if (this.type == 'add') {
        await calendarService.addAppointment(conflictCopy).then(() => {
          this.$toast.success('Termin wurde erstellt');
        });
      } else if (this.type == 'edit') {
        await calendarService.updateAppointment(conflictCopy).then(() => {
          this.$toast.success('Termin wurde gespeichert');
        });
      }
      this.conflicts = this.conflicts.filter(item => item !== conflict);
      if (this.conflicts.length == 0) {
        this.$emit('close');
      }
    }
  }
};