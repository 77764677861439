export default {
  data() {
    return {
      internalValue: this.value
    };
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
    }
  },
  props: {
    value: Boolean,
    // Value from v-model of the calling component
    maxWidth: {
      type: [String, Number],
      default: '700'
    },
    width: {
      type: [String, Number],
      default: '700'
    },
    persistent: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    hideOverlay: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'default'
    },
    centeredContent: {
      type: Boolean,
      default: false
    },
    cancelButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    cancelDialog() {
      this.$emit('cancel');
      this.closeDialog();
    },
    closeDialog() {
      // Emit an event to notify the parent component that the dialog is closed
      this.$emit('close');
      this.internalValue = false;
    },
    escPressed() {
      this.$emit('keydown-esc');
    },
    clickedOutside() {
      this.$emit('click-outside');
    },
    openDialog() {
      this.internalValue = true;
    }
  }
};