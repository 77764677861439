import { mapGetters } from 'vuex';
import store from '@/store';
export default {
  mounted: () => {
    store.dispatch('loadCompanyData');
  },
  computed: {
    ...mapGetters(['company', 'loggedIn']),
    VERSION() {
      return `Version: ${process.env.VUE_APP_VERSION}`;
    }
  }
};