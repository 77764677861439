import "core-js/modules/es.array.push.js";
import LBADialog from '@/components/ui/dialogs/LBADialog.vue';
export default {
  components: {
    LBADialog
  },
  props: {
    widgetList: Object
  },
  data: () => ({
    widgetOverviewVisible: false,
    selectedCategory: 0,
    widgetCategories: [],
    search: {},
    searchItems: []
  }),
  methods: {
    open() {
      // reset to default
      this.selectedCategory = 0;
      this.searchItems = [];

      // load widgetCategories
      let widgetCategories = Object.keys(this.widgetList);
      widgetCategories.sort();
      this.widgetCategories = widgetCategories;

      // create array for autocomplete
      widgetCategories.forEach((category, i) => {
        this.widgetList[category].forEach(widget => {
          this.searchItems.push({
            text: widget.displayName,
            value: {
              categoryIndex: i,
              widgetId: widget.widgetID
            }
          });
        });
      });

      // open dialog
      this.widgetOverviewVisible = true;
    },
    updateSelectedCategory() {
      if (this.search !== null) {
        this.selectedCategory = this.search.categoryIndex;
      } else {
        this.selectedCategory = 0;
      }
    },
    closeDialog() {
      this.widgetOverviewVisible = false;
      this.$emit('save');
    }
  }
};