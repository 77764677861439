import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VApp, {
    staticStyle: {
      "font-family": "\"Helvetica Neue LT Std\""
    }
  }, [_c('Header', {
    attrs: {
      "drawer": _vm.drawer
    },
    on: {
      "drawerChange": _vm.drawerChange
    }
  }), _vm.loggedIn ? _c('NavBar', {
    attrs: {
      "drawer": _vm.drawer
    },
    on: {
      "drawerChange": _vm.drawerChange
    }
  }) : _vm._e(), _c(VMain, {
    staticClass: "white"
  }, [_c('router-view')], 1), !_vm.isMobile() ? _c('Footer') : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };