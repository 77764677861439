import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VDialog, {
    attrs: {
      "transition": "dialog-bottom-transition",
      "max-width": "700",
      "scrollable": ""
    },
    model: {
      value: _vm.serialAppointmentSummary,
      callback: function ($$v) {
        _vm.serialAppointmentSummary = $$v;
      },
      expression: "serialAppointmentSummary"
    }
  }, [[_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    attrs: {
      "color": "primary",
      "dark": "",
      "fixed": "",
      "top": "",
      "flat": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.serialAppointmentSummary = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v(" Terminserie Zusammenfassung ")])], 1)], 1), _c(VCardText, {
    staticClass: "pt-5 pl-10"
  }, [_c(VSimpleTable, [_c('thead', [_c('td', {
    staticClass: "pl-4"
  }, [_c('b', [_vm._v("Nr.")])]), _c('td', {
    staticClass: "pl-4"
  }, [_c('b', [_vm._v("Von")])]), _c('td', {
    staticClass: "pl-4"
  }, [_c('b', [_vm._v("Bis")])])]), _c('tbody', _vm._l(_vm.serialPreviews, function (appointment, index) {
    return _c('tr', {
      key: appointment.start,
      staticClass: "text-p pa-2"
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormat(appointment.start, false)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormat(appointment.end, false)) + " ")])]);
  }), 0)])], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    staticClass: "white--text",
    attrs: {
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": _vm.createSerialAppointment
    }
  }, [_vm._v("Anlegen")])], 1)], 1)]], 2), _c('SerialReservedDataDialog', {
    ref: "serialResDataDialog",
    on: {
      "close": _vm.close
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };