import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

// CORE
import auth from '@/store/modules/core/auth.js'
import company from '@/store/modules/core/company.js'
import apps from '@/store/modules/core/apps.js'
import user from '@/store/modules/core/user.js'

// CRM
import crmAddresses from '@/store/modules/crm/addresses.js'
import crmInteractions from '@/store/modules/crm/interactions.js'
import crmDocuments from '@/store/modules/crm/documents.js'
import crmContacts from '@/store/modules/crm/contacts.js'
import crmCustomerGroups from '@/store/modules/crm/customerGroups.js'
import crmFilter from '@/store/modules/crm/filter.js'
import crmSettings from '@/store/modules/crm/crmSettings.js'

// STATISTICS
import statisticsWarehouses from '@/store/modules/statistics/warehouses.js'
import statisticsTimeFilter from '@/store/modules/statistics/timeFilter.js'
import statisticsRevenueTime from '@/store/modules/statistics/revenueTime.js'
import statisticsOffer from '@/store/modules/statistics/offer.js'
import statisticsCustomer from '@/store/modules/statistics/customer.js'
import vkCustomerRevenueStatistic from './modules/statistics/vkCustomerRevenue.js'

// CAL
// import calAppointments from '@/store/modules/cal/appointments.js'

//CAL-v2
import calAppointments from '@/store/modules/calendar_v2/appointments.js'
import calendarInformation from '@/store/modules/calendar_v2/calendarInformation.js'

// Article
import articlePermissions from '@/store/modules/article/articlePermissions.js'
import articleFilter from '@/store/modules/article/filter.js'
import articleDetails from '@/store/modules/article/articleDetails.js'
import articleStatisticsCharts from '@/store/modules/article_statistics/charts.js'
import articleStatisticsDataTable from '@/store/modules/article_statistics/datatable.js'

// Offer
import offerAddress from '@/store/modules/offer/offerAddress.js'
import offerArticle from '@/store/modules/offer/offerArticle.js'
import offerDetails from '@/store/modules/offer/offerDetails.js'

// Business
import businessDocument from './modules/business/businessDocument'
import businessDocumentGeneral from './modules/business/businessDocumentGeneral'
import businessDocumentDeliveryBilling from './modules/business/businessDocumentDeliveryBilling'

// Settings
import masterData from './modules/settings/masterData'
//import userSettings from './modules/settings/userSettings'

//Task
import task from './modules/task/task.js'

import notifications from '@/store/modules/notifications/notifications.js'
import shop from '@/store/modules/shop/shop.js'

import distributors from '@/store/modules/statistics/distributors.js'

// Dashboard
import widgets from './modules/home/widgets.js'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    company,
    apps,
    user,
    //calAppointments,
    crmAddresses,
    crmInteractions,
    crmContacts,
    crmCustomerGroups,
    crmDocuments,
    crmFilter,
    crmSettings,
    statisticsWarehouses,
    statisticsTimeFilter,
    statisticsRevenueTime,
    statisticsOffer,
    statisticsCustomer,
    articleDetails,
    articleFilter,
    articlePermissions,
    articleStatisticsCharts,
    articleStatisticsDataTable,
    offerAddress,
    offerArticle,
    offerDetails,
    businessDocument,
    businessDocumentGeneral,
    businessDocumentDeliveryBilling,
    notifications,
    shop,
    calAppointments,
    calendarInformation,
    masterData,
    //userSettings,
    distributors,
    task,
    widgets,
    vkCustomerRevenueStatistic
  }
})
