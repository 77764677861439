var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "dialogType": "warning"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Bearbeitung ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm._v(" Der Termin wurde während des Bearbeitens verändert. Wollen Sie Ihre Änderung übernehmen oder abbrechen? ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('LBAButton', {
          attrs: {
            "buttonType": "error",
            "buttonStyle": "text"
          },
          on: {
            "click": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Abbrechen ")];
            },
            proxy: true
          }])
        }), _c('LBAButton', {
          attrs: {
            "buttonType": "warning",
            "buttonStyle": "text"
          },
          on: {
            "click": _vm.forceUpdate
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Übernehmen ")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.forceDialog,
      callback: function ($$v) {
        _vm.forceDialog = $$v;
      },
      expression: "forceDialog"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };