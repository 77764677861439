import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VAutocomplete, {
    attrs: {
      "items": _vm.searchOutputs,
      "item-value": "id",
      "item-text": "name",
      "no-filter": "",
      "search-input": _vm.searchInputSync,
      "loading": _vm.searchOutputsearchLoading,
      "loading-text": "Globale Suche läuft",
      "no-data-text": "Keine Einträge gefunden",
      "label": "LBA durchsuchen (mind. 3 Zeichen)",
      "prepend-inner-icon": "mdi-magnify",
      "outlined": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchInputSync = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchInputSync = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function ({
        item
      }) {
        return [_c(VListItem, {
          on: {
            "click": function ($event) {
              return _vm.openSearchItem(item);
            }
          }
        }, [item.type == 'ADDRESS' ? _c(VAvatar, {
          staticClass: "primary",
          attrs: {
            "size": "36"
          }
        }, [_c(VIcon, {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("mdi-account-outline")])], 1) : _vm._e(), item.type == 'ARTICLE' ? _c(VAvatar, {
          staticClass: "primary",
          attrs: {
            "size": "36"
          }
        }, [_c(VIcon, {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("mdi-archive-outline")])], 1) : _vm._e(), item.type == 'DOCUMENT' ? _c(VAvatar, {
          staticClass: "primary",
          attrs: {
            "size": "36",
            "label": ""
          }
        }, [_c(VIcon, {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("mdi-file-document-outline")])], 1) : _vm._e(), _c(VListItemContent, {
          staticClass: "ml-4"
        }, [_c(VListItemTitle, [item.type == 'DOCUMENT' ? _c('span', [_vm._v(" " + _vm._s(item.object.shortType + ' ' + item.name + ' / ' + _vm.dateTimeService.getShortGermanDate(item.object.date) + ' (' + item.object.state + ')') + " " + _vm._s(_vm.displayItem(item)) + " ")]) : item.type == 'ADDRESS' ? _c('span', [_vm._v(" " + _vm._s(item.name + ', ' + item.object.kdStrasse + ', ' + item.object.kdPlz + ' ' + item.object.kdOrt) + " ")]) : item.type == 'ARTICLE' ? _c('span', [_vm._v(" " + _vm._s(item.name + ' (' + item.object.art_nr + ')') + " " + _vm._s(_vm.displayItem(item)) + " ")]) : _vm._e()])], 1), _c(VListItemAction, [_c(VIcon, [_vm._v("mdi-arrow-right")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.document.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.document, "customerId", $$v);
      },
      expression: "document.customerId"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };