import "core-js/modules/es.array.push.js";
import HomeService from '@/services/home/homeService.js';
import dateTimeService from '@/services/cal/dateTimeService.js';
export default {
  data: function () {
    return {
      headers: [{
        text: 'Nr',
        align: 'start',
        value: 'lfdnr'
      }, {
        text: 'Betreff',
        value: 'title'
      }, {
        text: 'Bis',
        value: 'dueDate'
      }],
      tasks: []
    };
  },
  methods: {
    taskDueDatePassed(task) {
      return dateTimeService.getMinutesDifference(new Date(), new Date(task.dueDate)) < 0;
    },
    getRowColor(task, defaultColor) {
      let minutesDiff = dateTimeService.getMinutesDifference(new Date(), new Date(task.dueDate));
      if (minutesDiff < 0) {
        return 'error';
      } else if (minutesDiff < 60) {
        return 'warning';
      } else {
        return defaultColor;
      }
    },
    styleDate(date) {
      return dateTimeService.getLocalTimeWithDateAndTime(new Date(date));
    },
    openTask(taskId) {
      this.$router.push({
        name: 'TaskDetails',
        params: {
          lfdnr: taskId
        }
      });
    }
  },
  async mounted() {
    this.tasks = await HomeService.getTasks();
  }
};