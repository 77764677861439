import axios from 'axios'
import router from '@/router'
import authService from '@/services/core/authService.js'

export default {
  state: {
    bearer: authService.getBearerFromLocalStorage(),
    refreshToken: authService.getRefreshTokenFromLocalStorage()
  },
  mutations: {
    SET_AUTH_DATA(state, authData) {
      state.bearer = authData.bearer
      state.refreshToken = authData.refreshToken

      authService.writeAuthToLocalStorage(authData)
    },
    CLEAR_AUTH_DATA(state) {
      state.bearer = undefined
      state.refreshToken = undefined
      localStorage.removeItem('auth')
    }
  },
  actions: {
    async loadAuthFromLocalStorage({ commit }) {
      var auth = await JSON.parse(localStorage.getItem('auth'))

      if (auth != null) {
        commit('SET_AUTH_DATA', auth)
      }
    },
    async setAuthData({ commit }, data) {
      commit('SET_AUTH_DATA', data)
    },
    login({ dispatch }, credentials) {
      return axios.post('/v1/login', credentials).then(response => {
        dispatch('setAuthData', response.data)
        if (!router.currentRoute.query.redirectTo) {
          router.currentRoute.query.redirectTo = '/'
        }
        router.push(router.currentRoute.query.redirectTo)
      })
    },
    reAuth({ commit }) {
      commit('CLEAR_AUTH_DATA')
      router.push({ name: 'Login' })
    },
    async logout({ commit, state }) {
      await axios.post('/v1/logout', { refreshToken: state.refreshToken })
      router.push('/login?logout=true')
      commit('CLEAR_AUTH_DATA')
      router.go()
    }
  },
  getters: {
    loggedIn(state) {
      return !!state.bearer
    }
  }
}
