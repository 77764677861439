import HomeService from '@/services/home/homeService.js';
import dateTimeService from '@/services/cal/dateTimeService';
export default {
  data: function () {
    return {
      interactions: [],
      dateTimeService
    };
  },
  async mounted() {
    this.interactions = await HomeService.getInteractionsLast();
  }
};