import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "650px"
    },
    model: {
      value: _vm.serialReservedDataDialog,
      callback: function ($$v) {
        _vm.serialReservedDataDialog = $$v;
      },
      expression: "serialReservedDataDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "warning",
      "fixed": "",
      "top": "",
      "dark": "",
      "flat": ""
    }
  }, [_c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Serienterminkonflikt")])], 1)], 1), _c(VCardText, {
    staticClass: "mx-0 px-0 my-3 py-0"
  }, [_c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, _vm._l(_vm.conflicts, function (conflict) {
    return _c(VCard, {
      key: conflict.appointment.key,
      staticClass: "mx-10 my-5",
      staticStyle: {
        "border": "1px solid #757575"
      },
      attrs: {
        "elevation": "0"
      }
    }, [_c(VCardTitle, [_vm._v(" " + _vm._s(conflict.appointment.title) + " ")]), _c(VCardText, [conflict.reservedAddresses.length > 0 ? _c('div', [_c('p', [_vm._v("Adresskonflikt:")]), _c('p', [_vm._v(_vm._s(conflict.reservedAddresses[0].name))])]) : _vm._e(), conflict.reservedObjects.length > 0 ? _c('div', [_c('p', [_vm._v("Objektkonflikt")]), _c('p', [_vm._v(_vm._s(conflict.reservedObjects[0].name))])]) : _vm._e(), conflict.reservedResources.length > 0 ? _c('div', [_c('p', [_vm._v("Ressourcenkonflikt")]), _c('p', [_vm._v(_vm._s(conflict.reservedResources[0].name))])]) : _vm._e()]), _c(VCardActions, [_c(VBtn, {
      staticClass: "orange--text d-flex justify-center",
      attrs: {
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.perform('CREATE_WITHOUT', conflict);
        }
      }
    }, [_c(VIcon, {
      staticClass: "mr-2 ml-n2"
    }, [_vm._v(" mdi-close ")]), _vm._v(" Kollision entfernen ")], 1), _c(VSpacer), _c(VBtn, {
      staticClass: "orange--text",
      attrs: {
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.perform('CREATE', conflict);
        }
      }
    }, [_c(VIcon, {
      staticClass: "mr-2 ml-n2"
    }, [_vm._v(" mdi-check ")]), _vm._v(" Kollision Übernehmen ")], 1)], 1)], 1);
  }), 1)], 1), _c(VCardActions, [_c(VBtn, {
    staticClass: "orange--text d-flex justify-center",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.performAll('CREATE_WITHOUT');
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2 ml-n2"
  }, [_vm._v(" mdi-close ")]), _vm._v(" Alle entfernen ")], 1), _c(VSpacer), _c(VBtn, {
    staticClass: "orange--text",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.performAll('CREATE');
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2 ml-n2"
  }, [_vm._v(" mdi-check ")]), _vm._v(" Alle übernehmen ")], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };