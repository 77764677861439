import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "my-4 pa-4 mx-2 d-flex justify-space-between"
  }, [_c('h2', {
    staticClass: "primary--text text-h4 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.greet) + " " + _vm._s(_vm.user.username) + "! ")]), _c('img', {
    staticStyle: {
      "max-height": "40px"
    },
    attrs: {
      "src": _vm.company.logo
    }
  })]), _c('SearchComponent', {
    staticClass: "px-6"
  }), _vm.FEATURE_DASHBOARD_EDITABLE ? _c('div', {
    staticClass: "d-flex justify-start"
  }, [_c('span', {
    staticClass: "ml-5 pa-2 pt-1 grey--text text--darken-2 rounded-lg font-weight-bold"
  }, [_vm._v("LAYOUT:")]), _c(VBtn, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.toggleGridEditable,
      expression: "toggleGridEditable"
    }],
    staticClass: "mr-2",
    class: {
      success: _vm.gridEditable,
      'white--text': _vm.gridEditable
    },
    attrs: {
      "icon": "",
      "outlined": ""
    },
    on: {
      "click": _vm.toggleGridEditable
    }
  }, [_c(VIcon, [_vm._v(_vm._s(this.gridEditable ? 'mdi-content-save' : 'mdi-pencil'))])], 1), _vm.gridEditable ? _c(VBtn, {
    attrs: {
      "icon": "",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.WidgetOverview.open();
      }
    }
  }, [_c(VIcon, [_vm._v(" mdi-store ")])], 1) : _vm._e(), _c('WidgetOverviewComponent', {
    ref: "WidgetOverview",
    attrs: {
      "widgetList": _vm.widgetList
    },
    on: {
      "save": function ($event) {
        return _vm.editWidgets();
      }
    }
  })], 1) : _vm._e(), _c('div', [_c('grid-layout', {
    staticClass: "mt-0",
    attrs: {
      "layout": _vm.layout,
      "responsive-layouts": _vm.responsiveLayouts,
      "col-num": 12,
      "row-height": 30,
      "is-draggable": _vm.gridEditable,
      "is-resizable": _vm.gridEditable,
      "responsive": true,
      "vertical-compact": true,
      "use-css-transforms": true,
      "margin": [25, 25]
    },
    on: {
      "update:layout": function ($event) {
        _vm.layout = $event;
      },
      "breakpoint-changed": _vm.breakpointChangedEvent
    }
  }, _vm._l(_vm.layout, function (item) {
    return _c('grid-item', {
      key: item.i,
      attrs: {
        "x": item.x,
        "y": item.y,
        "w": item.w,
        "h": item.h,
        "i": item.i
      }
    }, [_c('smart-widget', {
      staticStyle: {
        "border-left": "5px solid var(--v-primary-base)",
        "border-radius": "0px",
        "overflow": "hidden"
      },
      attrs: {
        "title": _vm.widgetLabels[item.i],
        "padding": [0, 20, 12, 20]
      }
    }, [_c('template', {
      slot: "toolbar"
    }, [_vm.gridEditable ? _c(VBtn, {
      staticClass: "me-2",
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeWidget(item.i);
        }
      }
    }, [_c(VIcon, [_vm._v("mdi-close")])], 1) : _vm._e()], 1), _c('div', {
      staticClass: "mt-4"
    }, [item.i == 'CLOCK' ? _c('ClockComponent') : _vm._e(), item.i == 'DOCUMENTS_LAST' ? _c('DocumentsLast') : _vm._e(), item.i == 'INTERACTIONS_LAST' ? _c('InteractionsLast') : _vm._e(), item.i == 'TELEMETRY_FAILED_LOGINS' ? _c('TelemetryFailedLoginsComponent') : _vm._e(), item.i == 'APPOINTMENTS' ? _c('AgendaComponent') : _vm._e(), item.i == 'CUSTOMERS_TODAY' ? _c('CustomerTodayComponent') : _vm._e(), item.i == 'OPEN_POSITIONS' ? _c('OpenPositions') : _vm._e(), item.i == 'STATISTIC_MONTH' ? _c('MonthStatisticComponent') : _vm._e(), item.i == 'STATISTIC_TURNOVER' ? _c('TurnoverStatisticComponent') : _vm._e(), item.i == 'STATISTIC_YEAR' ? _c('YearStatisticComponent') : _vm._e(), item.i == 'TASKS' ? _c('TasksComponent') : _vm._e()], 1)], 2)], 1);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };