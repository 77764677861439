import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "scrollable": true,
      "width": 1200,
      "max-width": 1200,
      "persistent": true
    },
    on: {
      "close": function ($event) {
        return _vm.closeDialog();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Dashboard Widget Store ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VRow, {
          staticClass: "pt-2 px-4 mx-0 my-0"
        }, [_c(VCol, {
          staticClass: "col-12 col-lg-3"
        }, [_c(VListItem, [_c(VListItemContent, [_c(VListItemTitle, {
          staticClass: "text-h6 mb-4",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" Widget-Übersicht ")]), _c(VListItemSubtitle, [_c(VAutocomplete, {
          staticClass: "pt-1",
          attrs: {
            "label": "Durchsuchen",
            "outlined": "",
            "prepend-inner-icon": "search",
            "dense": "",
            "items": _vm.searchItems,
            "auto-select-first": true
          },
          on: {
            "change": _vm.updateSelectedCategory
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })], 1)], 1)], 1), _c(VList, {
          attrs: {
            "dense": "",
            "nav": ""
          }
        }, [_c(VListItemGroup, {
          attrs: {
            "color": "primary"
          },
          model: {
            value: _vm.selectedCategory,
            callback: function ($$v) {
              _vm.selectedCategory = $$v;
            },
            expression: "selectedCategory"
          }
        }, _vm._l(_vm.widgetCategories, function (widgetCategory) {
          return _c(VListItem, {
            key: widgetCategory,
            attrs: {
              "link": ""
            }
          }, [_c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(widgetCategory))])], 1)], 1);
        }), 1)], 1)], 1), _c(VCol, {
          staticClass: "col-12 col-lg-9"
        }, [_vm._l(_vm.widgetCategories, function (widgetCategory) {
          return _c('div', {
            key: widgetCategory
          }, [_vm.widgetCategories[_vm.selectedCategory] == widgetCategory ? _c(VContainer, [_c('p', {
            staticClass: "text-h4 font-weight-bold primary--text"
          }, [_vm._v(" " + _vm._s(widgetCategory) + " ")]), _c(VRow, _vm._l(_vm.widgetList[widgetCategory], function (widget) {
            return _c(VCol, {
              key: widget.widgetID,
              staticClass: "col-12 col-lg-6"
            }, [_c(VCard, {
              staticClass: "border-left-primary-3",
              attrs: {
                "flat": "",
                "outlined": ""
              }
            }, [_c(VImg, {
              staticClass: "mx-2 my-2",
              attrs: {
                "src": widget.image
              }
            }), _c(VCardTitle, {
              staticClass: "font-weight-bold primary--text"
            }, [_vm._v(_vm._s(widget.displayName))]), _c(VCardActions, {
              staticClass: "d-flex justify-end"
            }, [_c(VBtn, {
              class: widget.active ? 'grey' : 'primary',
              attrs: {
                "icon": ""
              },
              on: {
                "click": function ($event) {
                  widget.active = !widget.active;
                }
              }
            }, [_c(VIcon, {
              attrs: {
                "color": "white"
              }
            }, [_vm._v(_vm._s(widget.active ? 'remove' : 'add'))])], 1)], 1)], 1)], 1);
          }), 1)], 1) : _vm._e()], 1);
        }), _vm.widgetCategories[_vm.selectedCategory] === undefined ? _c('div', [_c(VContainer, {
          staticClass: "pt-0 ps-4"
        }, [_c('p', {
          staticClass: "text-h4 font-weight-bold primary--text"
        }, [_vm._v(" Keine Kategorie ")]), _c('p', [_vm._v("Bitte wählen Sie eine Kategorie aus.")])])], 1) : _vm._e()], 2)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return undefined;
      },
      proxy: true
    }]),
    model: {
      value: _vm.widgetOverviewVisible,
      callback: function ($$v) {
        _vm.widgetOverviewVisible = $$v;
      },
      expression: "widgetOverviewVisible"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };