import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import store from '@/store';
import HomeService from '@/services/home/homeService.js';

/* Components */
import { GridLayout, GridItem } from 'vue-grid-layout';
import SearchComponent from '@/components/home/SearchComponent.vue';
import WidgetOverviewComponent from '@/components/home/WidgetOverviewComponent.vue';

// General
import ClockComponent from '@/components/home/ClockComponent.vue';

// CRM
import DocumentsLast from '@/components/home/DocumentsLast.vue';
import InteractionsLast from '@/components/home/InteractionsLast.vue';

// Telemetry
import TelemetryFailedLoginsComponent from '@/components/home/TelemetryFailedLoginsComponent.vue';

// Calendar
import AgendaComponent from '@/components/home/AgendaComponent.vue';

// Statistics
import CustomerTodayComponent from '@/components/home/CustomerTodayComponent.vue';
import OpenPositions from '@/components/home/OpenPositions.vue';
import MonthStatisticComponent from '@/components/home/MonthStatisticComponent.vue';
import TurnoverStatisticComponent from '@/components/home/TurnoverStatisticComponent.vue';
import YearStatisticComponent from '@/components/home/YearStatisticComponent.vue';

// Task
import TasksComponent from '@/components/home/TasksComponent.vue';
export default {
  name: 'Home',
  components: {
    WidgetOverviewComponent,
    GridLayout,
    GridItem,
    SearchComponent,
    ClockComponent,
    AgendaComponent,
    CustomerTodayComponent,
    TelemetryFailedLoginsComponent,
    DocumentsLast,
    TasksComponent,
    YearStatisticComponent,
    MonthStatisticComponent,
    InteractionsLast,
    TurnoverStatisticComponent,
    OpenPositions
  },
  data() {
    return {
      gridEditable: false,
      customLabels: {},
      layout: [],
      //Breakpoints defined for responsive layout, the parameter represents the width of different devices:lg(large), md(medium), sm(small), xs(extra small).
      responsiveLayouts: {},
      defaultResponsiveLayout: {
        xxs: [{
          x: 0,
          y: 6,
          w: 3,
          h: 6,
          i: 'TASKS',
          moved: false
        }, {
          x: 0,
          y: 12,
          w: 4,
          h: 14,
          i: 'APPOINTMENTS',
          moved: false
        }, {
          x: 0,
          y: 0,
          w: 2,
          h: 6,
          i: 'CLOCK',
          moved: false
        }],
        xs: [{
          x: 0,
          y: 5,
          w: 4,
          h: 6,
          i: 'TASKS',
          moved: false
        }, {
          x: 0,
          y: 11,
          w: 4,
          h: 11,
          i: 'APPOINTMENTS',
          moved: false
        }, {
          x: 0,
          y: 0,
          w: 4,
          h: 5,
          i: 'CLOCK',
          moved: false
        }],
        md: [{
          x: 0,
          y: 5,
          w: 4,
          h: 9,
          i: 'TASKS',
          moved: false
        }, {
          x: 4,
          y: 0,
          w: 6,
          h: 14,
          i: 'APPOINTMENTS',
          moved: false
        }, {
          x: 0,
          y: 0,
          w: 4,
          h: 5,
          i: 'CLOCK',
          moved: false
        }],
        sm: [{
          x: 0,
          y: 6,
          w: 2,
          h: 8,
          i: 'TASKS',
          moved: false
        }, {
          x: 2,
          y: 0,
          w: 4,
          h: 14,
          i: 'APPOINTMENTS',
          moved: false
        }, {
          x: 0,
          y: 0,
          w: 2,
          h: 6,
          i: 'CLOCK',
          moved: false
        }],
        lg: [{
          x: 0,
          y: 5,
          w: 5,
          h: 10,
          i: 'TASKS',
          moved: false
        }, {
          x: 5,
          y: 0,
          w: 7,
          h: 15,
          i: 'APPOINTMENTS',
          moved: false
        }, {
          x: 0,
          y: 0,
          w: 5,
          h: 5,
          i: 'CLOCK',
          moved: false
        }]
      },
      currentBreakPoint: '',
      greet: ['Guten Morgen', 'Guten Morgen', 'Guten Tag', 'Guten Abend'][parseInt(new Date().getHours() / 24 * 4)],
      widgetList: {}
    };
  },
  methods: {
    breakpointChangedEvent: function (newBreakpoint, newLayout) {
      this.responsiveLayouts[newBreakpoint] = newLayout;
      this.currentBreakPoint = newBreakpoint;
    },
    getWidgetTitle(id) {
      let displayName = id;
      Object.keys(this.widgetList).forEach(widgetCategory => {
        if (displayName !== id) return; // don't check in following categories when name is already found
        this.widgetList[widgetCategory].forEach(widget => {
          if (widget.widgetID === id) {
            displayName = widget.displayName;
            return;
          }
        });
      });
      return displayName;
    },
    toggleGridEditable() {
      //save grid
      if (this.gridEditable) this.saveWidgetLayout();
      this.gridEditable = !this.gridEditable;
    },
    addWidget(widgetId) {
      this.layout.push({
        x: this.layout.length * 2 % 12,
        y: this.layout.length + 12,
        // puts it at the bottom
        w: 4,
        h: 4,
        i: widgetId
      });
      this.responsiveLayouts[this.currentBreakPoint] = this.layout;
    },
    editWidgets() {
      const activeWidgetIds = this.layout.map(o => o.i);
      Object.keys(this.widgetList).forEach(widgetCategory => {
        const activeWidgets = this.widgetList[widgetCategory].filter(o => o.active).map(o => o.widgetID);

        // add all new widgets
        activeWidgets.forEach(widgetId => {
          if (!activeWidgetIds.includes(widgetId)) {
            this.addWidget(widgetId);
          }
        });
        const disabledWidgets = this.widgetList[widgetCategory].filter(o => !o.active).map(o => o.widgetID);

        // remove widgets from active ones that have been disabled in widget store
        disabledWidgets.forEach(widgetId => {
          if (activeWidgetIds.includes(widgetId) && widgetId !== 'CLOCK') {
            this.removeWidget(widgetId);
          }
        });
      });
    },
    removeWidget(widgetId) {
      // remove widget from layout
      const index = this.layout.map(item => item.i).indexOf(widgetId);
      this.layout.splice(index, 1);

      // deactivate widget
      Object.keys(this.widgetList).forEach(category => {
        this.widgetList[category].forEach(widget => {
          if (widget.widgetID == widgetId) {
            widget.active = false;
            return;
          }
        });
      });

      // update layout without saving
      this.responsiveLayouts[this.currentBreakPoint] = this.layout;
    },
    async saveWidgetLayout() {
      if (JSON.stringify(this.responsiveLayouts) === '{}') return;
      if (this.layout.length === 0) return;
      this.responsiveLayouts[this.currentBreakPoint] = this.layout;
      return await HomeService.saveWidgetLayout(this.responsiveLayouts);
    },
    async getWidgetLayout() {
      const widgetLayout = await HomeService.getWidgetLayout();
      if (Object.keys(widgetLayout).length === 0) {
        this.responsiveLayouts = this.defaultResponsiveLayout;
      } else {
        this.responsiveLayouts = widgetLayout;
      }
      this.layout = this.responsiveLayouts['sm'];
    },
    async getWidgetList() {
      const availableWidgets = await HomeService.getAvailableWidgets();
      // add 'active' property to each widget and check if widget is active
      Object.keys(availableWidgets).forEach(widgetCategory => {
        availableWidgets[widgetCategory] = availableWidgets[widgetCategory].map(element => {
          let active = false;
          this.layout.forEach(layoutWidget => {
            if (layoutWidget.i === element.widgetID) {
              active = true;
              return;
            }
          });
          return {
            ...element,
            active: active
          };
        });

        // make widget labels from request available to store
        availableWidgets[widgetCategory].forEach(widget => {
          this.$store.commit('SET_WIDGET_LABEL', {
            id: widget.widgetID,
            label: widget.displayName
          });
        });
      });
      this.widgetList = availableWidgets;
    }
  },
  computed: {
    FEATURE_DASHBOARD_EDITABLE() {
      return process.env.VUE_APP_FEATURE_DASHBOARD_EDITABLE === 'true';
    },
    ...mapGetters(['user', 'company', 'widgetLabels'])
  },
  async created() {
    store.dispatch('loadVisibleTabs');
    await this.getWidgetLayout();
    await this.getWidgetList();
  }
};